$startingColor: #f1e6b2;
$endingColor: #1d3c34;

@for $i from 0 through 100 {
  $color: mix($startingColor, $endingColor, percentage($i / 100));
  $luminance: (
      0.299 * red($color) + 0.587 * green($color) + 0.114 * blue($color)
    ) /
    255;
  $textColor: if($luminance> 0.4, #000000, #f1e6b2);

  .row-#{$i} {
    background-color: $color;
    color: $textColor;
    z-index: $luminance * 1000;
    > td {
      color: $textColor;
    }
  }
}
